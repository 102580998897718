import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import { HorizontalCard } from '../components/horizontal-card.js';
import Passes1 from '../assets/danforth-dance.png';
import Passes2 from '../assets/rdf-bw-group-outsideleghang-transformed-desktop.webp';
import Passes3 from '../assets/rdf-bw-group-backhookspin-transformed.webp';
import Passes4 from '../assets/rdf-dan-invert-compressed.webp';
import Passes5 from '../assets/carousel-two-transformed-desktop.webp';
import { useWindowSize } from '../hooks/use-window-size.js';
import ButtonSwitch from '../components/button-switch.js';
import PricingCard from '../components/pricing-card.js';
import { LogoContext } from '../context/LogoContext.js';

const ContainerDiv = styled.div`
    max-width: ${props => window.innerWidth < 1212 ? '100%' : '1170px'};
    margin: 0 ${props => (props.logoleftposition && window.innerWidth > 767) ? `${props.logoleftposition}px` : window.innerWidth > 584 ? '32px' : 'auto'};
    margin-top: -64px;

    ${props => props.padding && `@media (max-width: 680px) {
      padding: 2em;
    }`}
`

const PricingDiv = styled.div`
  max-width: 1370px;
  margin: 0 auto;
  display: flex;
  flex-direction: ${props => props.width <= 910 ? 'column' : 'row'};
  justify-content: space-around;

  @media (max-width: 1290px) {
    padding: 0em;
  }
`

const StyledH3 = styled.h3`
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  line-height: 54px;
  color: #0C120E;
  font-family: Inter;
`;

const StyledP = styled.p`
    text-align: left;
    font-family: "Inter-Regular", Helvetica;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 64px;

    @media (max-width: 1265px) {
      margin-bottom: 20px;
    }

    @media (max-width: 767px) {
      margin-bottom: 32px;
    }

    @media (max-width: 532px) {
      margin-bottom: 16px;
    }

    @media (max-width: 425px) {
      padding: 0 0em 1em 0em;
    }
`

const StyledSubHeading = styled.p`
    text-align: left;
    font-family: Inter;
    color: #6B7280;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;    
`

function PassesAndMemberships() {
  const [width, height] = useWindowSize(); 
  const [buttonSelected, setButtonSelected] = useState(0);

  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();

  useEffect(() => {
    document.title = 'Redefine Fit - Passes & Memberships';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  let perMonthPrice = [
    [115, 189, 215],
    [109, 179, 205]
  ]

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Passes & Memberships' coverImages={[RedefineDanforth]} height={'515px'} />

      <StyledDiv passes={true} bgColor={'#F5FFF7'}>
        <ContainerDiv width={width} logoleftposition={logoLeftPos && logoLeftPos}>
            <br/>
            <StyledH3>Drop-in Classes & Passes</StyledH3>
            <br/>
            <StyledSubHeading>We offer a variety of pricing options to meet your needs! New students can take advantage of our introductory offers. Afterwards, you can choose a drop-in class, a class pass, or join a cohort.</StyledSubHeading>
        </ContainerDiv>
      </StyledDiv>

      <StyledDiv passesMiddle={true} workshopEvents={true} bgColor={'#fff'}>
        <br/>
        <br/>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>
          <HorizontalCard passesBottom={true} firstPassCard={true} linkArray={['https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=1869384', 'https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=2758249']} headerText={'Introductory Offers'} specialFormatting={{'pbs': {'p1': `New to pole dance and fitness? For first-time students, get `, 'bs': ['5-classes/month with our introductory Special for $85+HST!']}}} specialFormattingTwo={{'pbs': {'p1': `Do you have some experience with pole, but are looking for a new studio to try? For first-time students with pole dance and fitness experience, get the `, 'bs': ['Experienced Poler’s Introductory Special for $99+HST for 5 classes!']}}} imagePath={Passes1} buttonText='BUY NOW' imageLeft={true} imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='600' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>

          <HorizontalCard passesBottom={true} linkArray={['https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=408211', 'https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=408182', 'https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=408168', 'https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=408180']} imageLeft={true} headerText={'Class Passes'} paragraphText={`Need flexibility in your schedule? You have up to one year from the date of purchase to use your class passes before they expire.`} listOffers={[{'title': 'Drop-In Class Pass', 'price': 29}, {'title': '5 Class Pass', 'price': 125}, {'title': '10 Class Pass', 'price': 235}, {'title': '30 Class Pass', 'price': 595}]} imagePath={Passes2} buttonText='BUY NOW' imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='600' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
          
          <HorizontalCard passesBottom={true} imageLeft={true} link='https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=1988266' headerText={'Cohorts'} paragraphText={`These 6-week sessions will allow you to remain with the same cohort of students each week, and progress together. This allows the instructor to build on content week after week, and tailor the content of the curriculum to meet your unique learning needs! The series are divided by level and by spin or static pole.`} imagePath={Passes3} buttonText='BUY NOW' imageWidth='540' textWidth='542' headingFontFamily='Inter' headingFontWeight='600' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
          
          <HorizontalCard passesBottom={true} linkArray={['https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=408213', 'https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=1765256', 'https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=408239']} headerText={'Practice Hours'} paragraphText={`Want to practice a new move you learned in class, or nail a choreography? Open pole practice happens on Mondays, Thursdays, and Fridays!`} listOffers={[{'title': 'Drop-In Practice Hour Pass', 'price': 20}, {'title': '5 Practice Hour Pass', 'price': 75}, {'title': '10 Practice Hour Pass', 'price': 150}]} imagePath={Passes4} buttonText='BUY NOW' imageLeft={true} imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='600' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
          
          <HorizontalCard passesBottom={true} imageLeft={true} link='/contact-us' headerText={'Private Classes'} specialFormatting={{p: `Our incredible team of instructors would be happy to offer 1-on-1 training for any level of pole dance or pole choreography!`, ul: ['$95/hour', '$425/5 hours (five 1-hour sessions)']}} buttonText='CONTACT US' imagePath={Passes5}  imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='600' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
        </ContainerDiv>
      </StyledDiv>

      <StyledDiv passes={true} bgColor={'#F5FFF7'}>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>
            <br/>
            <div style={{marginTop: '14px'}}></div>
            <StyledH3>Memberships</StyledH3>
            <br/>
            {width > 785 && <br/>}
            <StyledSubHeading>Dedicated to your passion for pole dance and fitness? Then join one of our memberships and save money! All membership options come with complimentary open pole practice sessions and discounts on additional classes. You can take classes at both of our studios.</StyledSubHeading>
        </ContainerDiv>
      </StyledDiv>

      <StyledDiv pricing={true} passesMemberships={true} workshopEvents={true} bgColor={'#fff'}>
        <br/>
        <ButtonSwitch setButtonSelected={setButtonSelected} buttonText1='Monthly' buttonText2='Auto-bill'/>
        <br/>
        <PricingDiv width={width}>
              <PricingCard firstCard={true} linkArray={['https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=5&k_id=1765260', 'https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=5&k_id=1765263']} passes={true} first={true} membershipTitle='Membership 5' perMonthPrice={perMonthPrice[buttonSelected][0]} orText='or, pay $324 for a 3-month membership' listItems={['5 classes a month', '5 complimentary practice sessions']} buttonText1='START MEMBERSHIP' buttonText2='BUY 3-MONTH MEMBERSHIP'/>
              <PricingCard secondCard={true} linkArray={['https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=5&k_id=1765279', 'https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=5&k_id=1765285']} passes={true} second={true} membershipTitle='Membership 10' favorite={true} perMonthPrice={perMonthPrice[buttonSelected][1]} orText='or, pay $515 for a 3-month membership' listItems={['10 classes a month', '10 complimentary practice sessions', 'Option to book cohort classes', 'Access to Virtual Studio']} buttonText1='START MEMBERSHIP' buttonText2='BUY 3-MONTH MEMBERSHIP'/>
              <PricingCard thirdCard={true} linkArray={['https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=5&k_id=1765304', 'https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=5&k_id=1765310']} passes={true} third={true} membershipTitle='Membership 15' perMonthPrice={perMonthPrice[buttonSelected][2]} orText='or, pay $585 for a 3-month membership' listItems={['15 classes a month', 'Unlimited complimentary practice sessions', 'Option to book cohort classes', 'Access to Virtual Studio']} buttonText1='START MEMBERSHIP' buttonText2='BUY 3-MONTH MEMBERSHIP'/>
        </PricingDiv>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <em><StyledP>📌 You can pause your membership for medical reasons. You may need a doctor's note to resume participation. Students can take a break from their studies every three months. They can take up to two breaks in a five-month period.</StyledP></em>
            <br/>
        </ContainerDiv>
      </StyledDiv>

      <StyledDiv noBottomMargin={true} passes={true} workshopEvents={true} bgColor={'#F5FFF7'}>
        <HorizontalCard video={true} headerText={'Need help finding the best option?'} paragraphText={`Watch the following video that breaks down the pricing option that suits your schedule, frequency of training, and budget!`} noButton={true} videoId={'YsaoqpiDLwY'}  imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
      </StyledDiv>

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default PassesAndMemberships;